<template>
	<div style="height: 100%;">
		<template>
			<main-table tool>
				<div slot="table" style="display: flex; flex-direction: column; flex: 1;">
					<div class="status_tabs">
						<div class="status_tab" :style="'background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
							<div style="font-size: 16px; color: #333; font-weight: bold;">{{status.count}}</div>
							<div style="font-size: 14px; color: #666; margin-top: 5px;">{{status.name}}<template v-if="s === 'added'">[已转化创建客户]</template></div>
							<div style="position: absolute; top: 5px; right: 5px; color: #fff; font-size: 18px; font-weight: 900;" v-if="status.checked">
								<i class="el-icon-check"></i>
							</div>
						</div>
						<div style="margin-bottom: 10px;">
							<el-button type="primary" size="mini" @click="show_fillter = !show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
						</div>
					</div>
					<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
						<el-form-item>
							<el-input placeholder="订单号/支付号/收件信息/订单产品" v-model="params.keyword" clearable></el-input>
						</el-form-item>
						<el-form-item v-if="!admin_user.shop_id">
							<el-select placeholder="搜索店铺" v-model="params.shop_id" filterable clearable>
								<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select placeholder="搜索销售" v-model="params.seller_id" filterable multiple collapse-tags>
								<el-option v-for="user in employees" :key="user.id" :label="user.name" :value="user.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-cascader placeholder="搜索地区" v-model="params.region_id" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" :show-all-levels="false" filterable clearable></el-cascader>
						</el-form-item>
						<el-form-item>
							<el-select placeholder="订单来源" v-model="params.source" filterable multiple collapse-tags>
								<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="s"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-cascader placeholder="请选择类目" v-model="params.categories" :options="tree_categories" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
						</el-form-item>
						<el-form-item>
							<el-select v-model="params.brand_id" placeholder="请选择品牌" filterable multiple collapse-tags>
								<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="params.goods_type_id" placeholder="请选择类型" filterable multiple collapse-tags>
								<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item>
							<el-select placeholder="订单状态" v-model="params.status" filterable multiple collapse-tags>
								<el-option v-for="(stat, s) in status" :key="s" :label="stat" :value="s"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item label-width="110px">
							<el-select slot="label" style="width: 100px;" placeholder="时间类型" v-model="params.at">
								<el-option label="支付时间" value="paid"></el-option>
								<el-option label="渠道时间" value="entered"></el-option>
								<el-option label="发货时间" value="shipped"></el-option>
								<el-option label="完成时间" value="finished"></el-option>
								<el-option label="更新时间" value="updated"></el-option>
								<el-option label="创建时间" value="created"></el-option>
							</el-select>
							<el-date-picker type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" v-model="params.date" :picker-options="$config.DATE_PICKER"></el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="getOrders({ ...params, page: 1, unbuyer: 0})">查询</el-button>
							<el-button type="primary" @click="getOrders({ ...params, page: 1, unbuyer: 1})">待分配</el-button>
						</el-form-item>
					</el-form>
					<div style="flex: 1; position: relative;">
						<el-table ref="orders_table" class="order scroll-wrapper absolute" height="100%" :data="data" :size="theme.size" :row-class-name="orderTableRowClassName">
							<el-table-column type="selection" width="42"></el-table-column>
							<el-table-column label="基本信息" min-width="230">
								<template slot-scope="scope">
									<p>
										<el-link type="primary" @click="$refs['show_dialog'].open(scope.row)"><i class="el-icon-s-order"></i> {{scope.row.no}}</el-link>
										<el-tooltip effect="dark" :content="scope.row.contract.no" placement="top-start" v-if="scope.row.contract">
											<span> 已签约</span>
										</el-tooltip>
									</p>
									<template v-if="scope.row.buyer">
										<p v-if="scope.row.buyer_id && scope.row.buyer_type == 'App\\Models\\Client'">
											<el-link type="primary" style="text-wrap: nowrap;" @click="$refs['show_client'].open({id: scope.row.buyer_id})"><i class="el-icon-s-custom"></i> {{scope.row.buyer.name}}</el-link>
										</p>
										<p v-else><i class="el-icon-user"></i> {{ scope.row.buyer.name}}</p>
									</template>
									<p v-if="!admin_user.shop_id"><i class="el-icon-office-building"></i> {{scope.row.shop ? scope.row.shop.name : theme.group}}</p>
									<p v-if="scope.row.seller"><i class="el-icon-s-cooperation"></i> {{scope.row.seller.name}}</p>
									<p v-if="scope.row.out_no">{{sources[scope.row.source]}} {{scope.row.out_no}}</p>
									<p v-if="scope.row.bill">{{payments[scope.row.bill.payment]}} {{scope.row.bill.paid_no}}</p>
								</template>
							</el-table-column>
							<el-table-column label="订单金额" min-width="120">
								<template slot-scope="scope">
									<p>商品 {{scope.row.goods_price | format_price}}</p>
									<p>优惠 -{{scope.row.discount_price | format_price}}</p>
									<p>运费 +{{scope.row.freight_price | format_price}}</p>
									<p>实付 {{scope.row.paid_price | format_price}}</p>
								</template>
							</el-table-column>
							<el-table-column label="收货信息" min-width="200">
								<template slot-scope="scope">
									<p v-if="!scope.row.address">无需物流配送</p>
									<template v-else>
										<p v-if="scope.row.mobile"><i class="el-icon-mobile-phone"></i> {{scope.row.mobile}} {{scope.row.linkman}}</p>
										<p v-if="scope.row.regions && scope.row.regions.length">
											<i class="el-icon-map-location"></i> <span v-for="region in scope.row.regions" :key="region.id">{{region.name}}</span>
										</p>
										<p v-if="scope.row.address"><i class="el-icon-place"></i> {{scope.row.address}}</p>
										<p v-if="scope.row.express"><i class="el-icon-truck"></i> {{expresses[scope.row.express]}} {{scope.row.express_no}}</p>
									</template>
								</template>
							</el-table-column>
							<el-table-column label="订购产品" min-width="200">
								<template slot-scope="scope">
									<p v-if="!scope.row.order_goods.length">暂无交付</p>
									<template v-else>{{scope.row.order_goods.map((r) => { return r.title; }).join('、')}}</template>
								</template>
							</el-table-column>
							<el-table-column label="操作时间" min-width="200" v-if="theme.width >= 1240">
								<template slot-scope="scope">
									<p>创建：{{scope.row.created_at}}</p>
									<p>更新：{{scope.row.updated_at}}</p>
									<p v-if="scope.row.bill">支付：{{scope.row.bill.paid_at}}</p>
									<p v-if="scope.row.entered_at">渠道：{{scope.row.entered_at}}</p>
									<p v-if="scope.row.shipped_at">发货：{{scope.row.shipped_at}}</p>
									<p v-if="scope.row.finished_at">完成：{{scope.row.finished_at}}</p>
								</template>
							</el-table-column>
							<el-table-column label="状态" min-width="80">
								<template slot-scope="scope">
									<el-tooltip effect="dark" placement="left" v-if="scope.row.delivery_services.length">
										<template slot="content">
											<p v-for="delivery in scope.row.delivery_services" :key="delivery.id">{{delivery.employee.name}}老师 {{delivery.delivery_at}} 完成《{{services[delivery.service]||'未知服务'}}》{{delivery.offline ? '线下交付' : '线上交付'}}</p>
										</template>
										<el-link type="primary" @click="$refs['show_dialog'].onStatus(scope.row)">{{status[scope.row.status]}}</el-link>
									</el-tooltip>
									<el-link type="primary" @click="$refs['show_dialog'].onStatus(scope.row)" v-else>{{status[scope.row.status]}}</el-link>
								</template>
							</el-table-column>
							<el-table-column label="操作" min-width="80">
								<el-dropdown slot="header" @command="batchAction">
									<el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="creater" :disabled="!$utils.create($api.URI_ORDERS)">添加订单</el-dropdown-item>
										<el-dropdown-item command="customer" :disabled="!$utils.create($api.URI_CLIENTS)">分配客户</el-dropdown-item>
										<el-dropdown-item command="orders">导出订单</el-dropdown-item>
										<el-dropdown-item command="products">导出产品</el-dropdown-item>
										<el-dropdown-item command="delete" :disabled="!$utils.delete($api.URI_ORDERS)">删除选中</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
								<template slot-scope="scope">
									<el-button type="text" @click="$refs['show_dialog'].onDelete(scope.row)" :disabled="!$utils.delete($api.URI_ORDERS)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<el-pagination
					slot="footer"
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					@size-change="(v) => { getOrders({ ...params, page: 1, perPage: v}); $refs.orders_table.bodyWrapper.scrollTop = 0; }"
					@current-change="(v) => { getOrders({ ...params, page: v}); $refs.orders_table.bodyWrapper.scrollTop = 0; }"
					background>	
				</el-pagination>
			</main-table>
			<el-dialog width="360px" title="分配客户" :visible.sync="distribute_dialog" :fullscreen="theme.width < 360" :before-close="closeAssigning" append-to-body>
				<div v-if="assigning && distribute.selection">
					<h3>正在分配客户 {{assign_current}}/{{distribute.selection.length}}</h3>
					<el-progress :percentage="Math.round((assign_current/distribute.selection.length)*100)"></el-progress>
				</div>
				<el-form ref="distribute_form" label-width="80px" :model="distribute" :size="theme.size" v-else status-icon>
					<el-form-item label="分配数量" v-if="distribute.selection">{{distribute.selection.length}} 条订单</el-form-item>
					<el-form-item label="业务专员" prop="employee_id">
						<el-select v-model="distribute.employee_id" placeholder="请选择业务专员" style="width: 100%;" filterable clearable>
							<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('distribute_form')">提交分配</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<show-client ref="show_client"></show-client>
			<order-create ref="create_order" @refresh="getOrders(params)" :shops="shops" :status="status" :sources="sources" :expresses="expresses"></order-create>
			<show-fee ref="fee_dialog" @refresh="getOrders(params)"></show-fee>
			<order-show
				ref="show_dialog"
				:employees="employees"
				@refresh="getOrders(params)"
				@edit="(c) => { $refs['create_order'].open(c); }"
				@show-contract="(c) => { $refs['contract_dialog'].open(c); }"
				@contract-status="(c) => { $refs['contract_dialog'].onStatus(c) }">
			</order-show>
			<show-contract ref="contract_dialog" @refresh="getOrders(params)" @create-fee="(f) => { $refs['fee_dialog'].open(f); }"></show-contract>
		</template>
	</div>
</template>


<style>
	.status_tabs {
		display: flex; flex-wrap: wrap;
	}
	.status_tabs .status_tab {
		min-width: 100px; padding: 10px; margin-right: 20px; margin-bottom: 20px; border-radius: 6px; box-shadow: 0 0 10px 5px #f1f1f1; position: relative; cursor: pointer; 
	}
	.el-table.order .info-row {
		background: #f4f4f5;
	}
	.el-table.order .danger-row {
		background: #fef0f0;
	}
	.el-table.order .warning-row {
		background: #fdf5e6;
	}
	.el-table.order .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	import orderShow from './show';
	import orderCreate from './create';
	import showContract from '@/pages/crm/contracts/show';
	import showClient from '@/pages/crm/clients/show';
	import showFee from '@/pages/crm/fees/show';

	export default {
		components: {
			mainTable,
			orderShow,
			orderCreate,
			showContract,
			showClient,
			showFee
		},
		computed: {
			...mapState(['theme', 'admin_user', 'regions', 'goods']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			tree_categories () {
				return this.$utils.buildTree(this.goods.categories || []);
			},
			goods_types () {
				return this.goods.goods_types || [];
			},
			brands () {
				return this.goods.brands || [];
			}
		},
		filters: {
			format_price (value) {
				return (value/100).toFixed(2);
			}
		},
		methods: {
			orderTableRowClassName ({row}) {
				switch (row.status) {
					case 'closed':
						return 'info-row';
					case 'shipped':
						return 'warning-row';
					case 'payed':
						return 'success-row';
					case 'refunded':
						return 'danger-row';
					default:
						return '';
				}
			},
			batchAction (action) {
				if (action === 'creater') {
					this.order = {source: 'inside', out_no: ''};
					this.$refs['create_order'].open();
					// this.order_dialog = true;
					return;
				}
				const { selection } = this.$refs.orders_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				switch (action) {
					case 'customer':
						for (let order of selection) {
							if (!order.mobile) return this.$message.error('选中 '+order.no+' 订单无手机号码信息！');
							if (!order.linkman) return this.$message.error('选中 '+order.no+' 订单无联系人信息！');
							if (order.buyer_id && order.buyer_type === "App\\Models\\Client") return this.$message.error('选中 '+order.no+' 订单已转为客户！');
						}
						this.distribute_dialog = true;
						this.distribute = { ...this.distribute, selection };
					break;
					case 'orders':
						this.$confirm(`此操作将导出${selection.length}条订单记录, 是否继续？`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							selection.forEach((c) => {
								c.goods_price_unit = (c.goods_price/100).toFixed(2);
								c.discount_price_unit = (c.discount_price/100).toFixed(2);
								c.freight_price_unit = (c.freight_price/100).toFixed(2);
								c.paid_price_unit = (c.paid_price/100).toFixed(2);
								c.status_name = this.status[c.status];
								c.seller_name = c.seller ? c.seller.name : '未知';
								c.buyer_name = c.buyer ? c.buyer.name : '未知';
							});
							var file = "销售订单导出" + this.$moment().format('YYYYMMDDHHmmss');
							var header = [['流水号', '业务员', '买家', '商品金额', '优惠金额', '运费金额', '实付金额', '状态', '支付时间', '发货时间', '完成时间', '更新时间', '创建时间']];
							var filter = ['no', 'seller_name', 'buyer_name', 'goods_price_unit', 'discount_price_unit', 'freight_price_unit', 'paid_price_unit', 'status_name', 'paid_at', 'shipped_at', 'finished_at', 'updated_at', 'created_at'];
							this.$utils.handleDownload(header, filter, selection, file, "订单明细");
						});
					break;
					case 'products':
						this.$confirm(`此操作将导出${selection.length}条订单产品, 是否继续？`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							var res = await this.$http.post(this.$api.URI_ORDERS, { action: 'goods', orders: selection.map((c) => { return c.id; }) });
							var { code, msg: message, result } = res.data;
							if (code != 0) return this.$message.error(message);
							result.forEach((c) => {
								c.shop_price = (c.shop_price/100).toFixed(2);
								c.status = this.status[c.status];
								c.source = this.sources[c.source];
							});
							var file = "订单产品导出" + this.$moment().format('YYYYMMDDHHmmss');
							var header = [['流水号', '站点', '客户', '销售', '品牌', '类型', '类目', '编码', '产品', '金额', '状态', '来源', '支付时间', '发货时间', '完成时间', '更新时间', '创建时间']];
							var filter = ['no', 'shop', 'client', 'seller', 'brand', 'type', 'category', 'number', 'title', 'shop_price', 'status', 'source', 'paid_at', 'shipped_at', 'finished_at', 'updated_at', 'created_at'];
							this.$utils.handleDownload(header, filter, result, file, "产品明细");
						});
					break;
					case 'delete':
						this.$confirm(selection.length + ' 条订单记录', '确定要删除选择记录吗？', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							let index = 0;
							let notify = this.$notify({
								title: '提示',
								dangerouslyUseHTMLString: true,
								message: '<p>正在准备删除...</p>',
								showClose: false,
								duration: 0
							});
							for (var o in selection) {
								let { id } = selection[o];
								index += 1;
								notify.message = '<p>正在删除 ' + index + ' / ' + selection.length + '</p>';
								await this.$http.get(this.$api.URI_ORDERS, {params: {action: "delete", id}, headers: {loading: true}});
							}
							if (index === selection.length) {
								notify.message = '<p>删除完成！</p>';
								setTimeout(() => {
									notify.close();
									this.getOrders(this.params);
								}, 3000);
							}
						});
					break;
				}
			},
			closeAssigning (done) {
				if (!this.assigning) return done();
				this.$confirm('是否中断分配操作？').then(() => {
					this.assigning = false;
					done();
				});
			},
			closeAssigned () {
				this.assigning = false;
				this.distribute_dialog = false;
				this.assign_current = 0;
				this.getOrders(this.params);
			},
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getOrders(this.params);
			},
			async submitClient (data) {
				const res = await this.$http.post(this.$api.URI_CLIENTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'distribute_form':
							var clues = this.distribute.selection || [];
							if (!clues.length) return this.$message.error("请选择分配订单");
							this.assigning = true;
							for (let c in clues) {
								if (!this.assigning) {
									this.closeAssigned();
									break;
								}
								this.assign_current++;
								if (!clues[c].linkman) {
									this.$message.error("未知订单名称");
									continue;
								}
								if (!clues[c].mobile) {
									this.$message.error("未知订单联系方式");
									continue;
								}
								await this.submitClient({source_model_type: 'App\\Models\\Order', source_model_id: clues[c].id, employee_id: this.distribute.employee_id});
							}
							if (this.assign_current === clues.length) {
								setTimeout(() => {
									this.closeAssigned();
								}, 1000);
							}
						break;
					}
				});
			},
			async submitOrder (data) {
				const res = await this.$http.post(this.$api.URI_ORDERS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.order_dialog = false;
						this.getOrders(this.params);
					}
				});
			},
			async getOrders (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_ORDERS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.data = result.data;
				this.employees = result.employees;
				this.shops = result.shops;
				this.status = result.status;
				this.sources = result.sources;
				this.payments = result.payments;
				this.services = result.services;
				this.expresses = result.expresses;
				var status_tabs = {};
				const status = params.status || [];
				for (var s in this.status) {
					status_tabs[s] = {
						name: this.status[s],
						count: result.counts[s] || 0,
						checked: status.indexOf(s) >= 0 ? true : false
					};
				}
				this.status_tabs = status_tabs; 
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				data: [],
				shops: [],
				services: [],
				employees: [],
				status: {},
				sources: {},
				payments: [],
				expresses: {},
				distribute: {},
				status_tabs: {},
				assign_current: 0,
				order: {},
				assigning: false,
				order_dialog: false,
				show_fillter: false,
				distribute_dialog: false,
				params: {
					perPage: 10,
					status: [],
					at: 'paid'
				}
			}
		},
		async created () {
			this.getOrders(this.params, true);
		}
	};
</script>